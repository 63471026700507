<template>
  <v-container
      fill-height
      fluid
      grid-list-xl
  >
    <v-layout
        justify-center
        wrap
    >
      <template>
        <data-table ref="datatable"
                    :key="'therapy-pricing-items'"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Therapy Pricing Items'"
                    :user_id="user_id"
                    :user_type="user_type"
                    :is-custom-filter="false"
                    :isDetail="false"
                    :isEdit="true"
                    :isDelete="true"
        >
          <template slot="datatable-actions">
            <div class="action-buttons ml-3">
              <button class="btn btn-primary" @click="showPricingItemModal">{{ addPricingItemButtonText }}</button>
            </div>
          </template>
        </data-table>
      </template>


      <add-therapy-pricing-items v-if="addPricingItemDialog"
                                 :visible="addPricingItemDialog"
                                 :action="action"
                                 :pre-selected-pricing-id="preSelectedPricingId"
                                 :value="selectedPricingItem"
                                 v-on:submitTherapyPricingItem="submitPricingItem"
                                 v-on:hide="hidePricingItemModal"
      ></add-therapy-pricing-items>
      <!-- delete dialog -->
      <confirmation v-if="confirmationDialog" :visible="confirmationDialog">
        <template slot="title">
          Are you sure?
        </template>
        <template slot="desc">
          This pricing item will be removed. This action cannot be undone.
        </template>
        <template slot="actions">
          <a class="cancel-model" @click="hideDeleteConfirmation">Cancel</a>
          <button class="btn modal-action-button confirmation-delete" @click="removeTherapyPricingItem">Delete</button>
        </template>
      </confirmation>
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '../../components/common/dataTable/DataTable'
import Confirmation from '@/components/common/Confirmation'
import AddTherapyPricingItems from '@/components/configuration/Pricing/AddTherapyPricingItems'

export default {
  name: 'TherapyPricingItems',
  components: {
    DataTable,
    AddTherapyPricingItems,
    Confirmation,
  },
  props: {
    user_id: {
      user_id: [Number, String]
    },
    user_type: {
      type: String
    },
    header: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      apiurl: 'therapy-pricing-items',
      search: {
        placeholder: 'Search',
        searchAble: false
      },
      sortOrder: [
        {
          field: 'id',
          sortField: 'id',
          direction: 'desc'
        }
      ],
      action: 'add',
      addPricingItemButtonText: 'Create Therapy Pricing Item',
      addPricingItemDialog: false,
      selectedPricingItem: null,
      confirmationDialog: false,
      preSelectedPricingId: 0,
    }
  },
  created() {
    this.preSelectedPricingId = this.$route.params.id;
    this.apiurl = this.apiurl + "?id=" + this.preSelectedPricingId;
  },
  methods: {
    showPricingItemModal () {
      this.addPricingItemDialog = true
    },
    showDeleteConfirmation(p) {
      this.selectedPricingItem = p;
      this.confirmationDialog = true;
    },
    hideDeleteConfirmation() {
      this.confirmationDialog = false;
    },
    hidePricingItemModal () {
      this.action = 'add'
      this.selectedPricingItem = null
      this.addPricingItemDialog = false
    },
    submitPricingItem (data, action) {
      let loader = this.$loading.show();
      data.action = action;
      this.$http.put(this.url+'therapy-pricing-item', {
        ...data,
        discount_applied: data.discount_applied ? "true" : "false"
      }).then((response) => {
        loader.hide();
        this.action = 'add'
        this.selectedPricingItem = null
        this.hidePricingItemModal()
        this.$events.fire('vuetable:reload')
        this.$toastr('success', response.data.message);
      }).catch((error) => {
        loader.hide();
        this.$toastr('error', error.response.data.message);
      });
    },
    removeTherapyPricingItem() {
      let loader = this.$loading.show();
      this.hideDeleteConfirmation();
      this.$http.delete(this.url+'therapy-pricing-item', {
        data: {
          id: this.selectedPricingItem.id
        }
      }).then((response) => {
        loader.hide();
        this.selectedPricingItem = null;
        this.$toastr('success', response.data.message);
        this.$events.fire('vuetable:reload');
      }).catch((error) => {
        loader.hide();
        this.$toastr('error',error.response.data.message);
      })
    }
  },
  events: {
    'edit-record': function (data) {
      this.action = 'update'
      this.selectedPricingItem = data
      this.showPricingItemModal()
    },
    'delete-record': function(selectedPricingItem) {
      this.showDeleteConfirmation(selectedPricingItem);
    },
  },
}
</script>
